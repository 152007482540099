import React from "react";
import { cn } from "../lib/helpers";
import Icon from "./icon";

import * as styles from "../styles/components/product-documentation.module.css";

const Documentation = ({ downloads }) => {

  return (
    <div className={styles.root}>
      <div className="grid">
        <div className="col-12 col-md-6">
          <h4 className={cn("h3", styles.title)}>Downloads</h4>
          <ul>
            {downloads.map(({ _key, download }) => {
              return (
                <>
                  {download && download.file && download.file.asset && (
                    <li key={_key}>
                      <a href={download.file.asset.url + "/" + download.file.asset.originalFilename} className="download--link" target="_blank" rel="noreferrer">
                        <Icon symbol="download" />
                        {download.title}
                      </a>
                    </li>

                  )}
                </>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Documentation;
