import React from "react";
import { Link } from "gatsby";
import { cn } from "../lib/helpers";
import PortableText from "./portableText";
import Icon from "./icon";

import * as styles from "../styles/components/product-technical-details.module.css";

const TechnicalDetails = (props) => {

  const {
    technicalDetails,
    configuratorLink
  } = props;

  return (
    <div className={styles.root}>
      <div className="grid">
        <div className={cn("col-12 col-md-6", configuratorLink && configuratorLink.url && styles.colMain)}>
          <h4 className={cn("h3", styles.title)}>Specifications</h4>
          <PortableText blocks={technicalDetails} />
        </div>
        {configuratorLink && configuratorLink.url && (
          <div className={cn("col-12 col-md-6", styles.colAnchors)}>
            <h4 className={cn("h3", styles.title)}>Configurator</h4>
            {configuratorLink.blank ? (
              <a href={configuratorLink.url} target="_blank" rel="noreferrer" className="external--link">
                <Icon symbol="external" />
                {configuratorLink.text}
              </a> 
            ) : (
              <Link to={configuratorLink.url} className="external--link">
                <Icon symbol="external" />
                {configuratorLink.text}
              </Link>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default TechnicalDetails;
