import React from "react";
import { cn } from "../lib/helpers";
import Container from "./container";
import Icon from "./icon";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";
import Fancybox from "./fancybox";
import { Link } from 'react-scroll';
import { Helmet } from 'react-helmet';

import * as styles from "../styles/components/page-header-product.module.css";

const PageHeader = (props) => {

  const {
    productHero,
    mobileHero,
    title,
    description,
    whiteHeaderElements,
    enableGallery,
    technicalDetails,
    notificationBar
  } = props;

  return (
    <div className={styles.root}>

      <Helmet>
        <body className={whiteHeaderElements ? "text-color-change" : "product-page"} />
      </Helmet>

      {productHero && productHero.asset && (
        <picture className={styles.image}>
          {mobileHero && mobileHero.asset && (
            <>
              <source media="(min-width:768px)" srcSet={imageUrlFor(buildImageObj(productHero)).auto("format").url()} />
              <source media="(max-width:767px)" srcSet={imageUrlFor(buildImageObj(mobileHero)).auto("format").url()} />
            </>
          )}
          <img src={imageUrlFor(buildImageObj(productHero)).auto("format").url()} alt={productHero.alt} />
        </picture>
      )}

      <Container>
        <div className={styles.titleRow}>
          <div className="grid">
            <div className="col-12 col-md-6">
              {title && (<h1 className={styles.title}>{title}</h1>)}
            </div>
            <div className="col-12 col-md-6">
              {enableGallery && (
                <Fancybox options={{ 
                  infinite: false,
                  dragToClose: false,
                  keyboard: false,
                  Toolbar: {
                    display: [
                      { id: "close", position: "right" },
                    ],
                  },
                  Thumbs: {
                    autoStart: false,
                  },
                }}>
                  <a className={styles.galleryLink} data-fancybox-trigger="product-gallery">
                    <Icon symbol="gallery" />
                    View Gallery
                  </a>
                </Fancybox>
              )}
            </div>
          </div>
        </div>

        <div className={styles.description}>
          <div className="grid">
            <div className="col-12 col-xl-6">
              <div className={styles.content}>
                {description && (<h2>{description}</h2>)}
                {technicalDetails && (
                  <Link 
                    to="technical-details"
                    spy={false}
                    smooth={true}
                    hashSpy={false}
                    offset={(notificationBar && notificationBar.link && notificationBar.url) ? -150 : -100}
                    duration={800}
                    delay={0}
                    isDynamic={true}
                    ignoreCancelEvents={false}
                    spyThrottle={0}
                    className="anchor--link"
                  >
                    <Icon symbol="arrow-down" />
                    Technical Details
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default PageHeader;
