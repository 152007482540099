import React from "react";
import Container from "./container";
import { cn } from "../lib/helpers";
import ProductCard from "./product-card";

import * as styles from "../styles/components/products-list.module.css";

const ProductObjectsList = ({ title, products }) => {

  return (
    <div className={styles.root}>
      <Container>
        {title && (<h4 className={cn("h3", styles.title)}>{title}</h4>)}
        <div className="grid">
          {products.map(({ product, _key }) => (
            <div key={_key} className={cn("col-12 col-md-6 col-xl-4", styles.item)}>
              <ProductCard product={product} />
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
}

export default ProductObjectsList;
