import React from "react";
import { Link } from 'react-scroll';
import { cn } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";
import Icon from "./icon";
import Fancybox from "./fancybox";
import PortableText from "./portableText";

import * as styles from "../styles/components/product-color-panel.module.css";

const ColorPanel = (props) => {

  const {
    type,
    type2,
    typeParents,
    type2Parents,
    typeSlug,
    type2Slug,
    notificationBar
  } = props;

  function handleClosePanel() {
    const items = document.getElementsByClassName('cp-active');
    Array.from(items).forEach(function(el){
      el.classList.remove('cp-active');
    });
  }

  function handleOpenPanel(e) {
    handleClosePanel();
    e.target.parentElement.classList.add('cp-active');
    document.getElementById(e.target.parentElement.getAttribute("data-target")).classList.add('cp-active');
  }

  return (
    <div className={styles.root}>
      <div className={styles.children}>
        <Fancybox options={{ 
          infinite: false,
          dragToClose: false,
          keyboard: false,
          Toolbar: {
            display: [
              { id: "close", position: "right" },
            ],
          },
          Thumbs: {
            autoStart: false,
          },
        }}>
          {typeParents.map(({node}) => {

            const typeChildren = type.filter(post => (post.node.parentItem && post.node.parentItem.id === node.id));

            const id = typeSlug + "-" + node.slug.current;

            return (

              <div id={id} className={styles.child}>
                <div className={styles.header}>
                  <h4>
                    {node.title}
                    <button
                      className={styles.close}
                      onClick={handleClosePanel}
                    >
                      <Icon symbol="close" />
                      <span className="screen-reader-text">Close Child Colour Panel</span>
                    </button>
                  </h4>
                </div>
                {node._rawTechnicalDetails && <div className={styles.technicalDetails}><PortableText blocks={node._rawTechnicalDetails} /></div>}
                <div className={styles.grid}>
                  {typeChildren.map(({node}) => (
                    <div key={node.id} className={styles.item}>
                      {(node.image && node.image.asset) ? (
                        <a 
                          data-src={imageUrlFor(buildImageObj(node.image))
                                  .auto("format")
                                  .url()} 
                          data-fancybox={"gallery-"+id}
                          data-caption={"&nbsp;"+node.title+"&nbsp;"}
                          className={styles.link}
                        >
                          <div className={styles.image}>
                            <img
                              src={imageUrlFor(buildImageObj(node.image))
                                .width(350)
                                .url()}
                              alt={node.image.alt}
                            />
                          </div>
                          {node.title && (
                            <h4 className={styles.title}>
                              {node.title}
                            </h4>
                          )}
                        </a>
                      ) : (
                        <>
                          {node.title && (
                            <h4 className={styles.title}>
                              {node.title}
                            </h4>
                          )}
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </div>

            )

          })}

          {type2 && (

            <>

            {type2Parents.map(({node}) => {

              const type2Children = type2.filter(post => (post.node.parentItem && post.node.parentItem.id === node.id));

              const id2 = type2Slug + "-" + node.slug.current;

              return (

                <div id={id2} className={styles.child}>
                  <div className={styles.header}>
                    <h4>
                      {node.title}
                      <button
                        className={styles.close}
                        onClick={handleClosePanel}
                      >
                        <Icon symbol="close" />
                        <span className="screen-reader-text">Close Child Colour Panel</span>
                      </button>
                    </h4>
                  </div>
                  {node._rawTechnicalDetails && <div className={styles.technicalDetails}><PortableText blocks={node._rawTechnicalDetails} /></div>}
                  <div className={styles.grid}>
                    {type2Children.map(({node}) => (
                      <div key={node.id} className={styles.item}>
                        {(node.image && node.image.asset) ? (
                          <a 
                            data-src={imageUrlFor(buildImageObj(node.image))
                                    .auto("format")
                                    .url()} 
                            data-fancybox={"gallery-"+id2}
                            data-caption={"&nbsp;"+node.title+"&nbsp;"}
                            className={styles.link}
                          >
                            <div className={styles.image}>
                              <img
                                src={imageUrlFor(buildImageObj(node.image))
                                  .width(350)
                                  .url()}
                                alt={node.image.alt}
                              />
                            </div>
                            {node.title && (
                              <h4 className={styles.title}>
                                {node.title}
                              </h4>
                            )}
                          </a>
                        ) : (
                          <>
                            {node.title && (
                              <h4 className={styles.title}>
                                {node.title}
                              </h4>
                            )}
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                </div>

              )

            })}

            </>

          )}

        </Fancybox>

      </div>

      <div className={styles.parents}>
        <div className={styles.grid}>

          {typeParents.map(({node}) => {

            const id = typeSlug + "-" + node.slug.current;

            return (

              <div key={node.id} className={styles.item} data-target={id}>
                <Link 
                  to={id}
                  spy={false}
                  smooth={true}
                  hashSpy={false}
                  offset={(notificationBar && notificationBar.link && notificationBar.url) ? -150 : -100}
                  duration={400}
                  delay={0}
                  isDynamic={true}
                  ignoreCancelEvents={false}
                  spyThrottle={0}
                  className={styles.link}
                  onClick={handleOpenPanel}
                >
                  {node.image && node.image.asset && (
                    <div className={styles.image}>
                      <img
                        src={imageUrlFor(buildImageObj(node.image))
                          .width(350)
                          .url()}
                        alt={node.image.alt}
                      />
                      <Icon symbol="multi" />
                    </div>
                  )}
                  {node.title && (
                    <h4 className={styles.title}>
                      {node.title}
                      <Icon symbol="plus" />
                    </h4>
                  )}
                </Link>
              </div>

            )

          })}

          {type2 && (

            <>

            {type2Parents.map(({node}) => {

              const id2 = type2Slug + "-" + node.slug.current;

              return (

                <div key={node.id} className={styles.item} data-target={id2}>
                  <Link 
                    to={id2}
                    spy={false}
                    smooth={true}
                    hashSpy={false}
                    offset={(notificationBar && notificationBar.link && notificationBar.url) ? -150 : -100}
                    duration={400}
                    delay={0}
                    isDynamic={true}
                    ignoreCancelEvents={false}
                    spyThrottle={0}
                    className={styles.link}
                    onClick={handleOpenPanel}
                  >
                    {node.image && node.image.asset && (
                      <div className={styles.image}>
                        <img
                          src={imageUrlFor(buildImageObj(node.image))
                            .width(350)
                            .url()}
                          alt={node.image.alt}
                        />
                        <Icon symbol="multi" />
                      </div>
                    )}
                    {node.title && (
                      <h4 className={styles.title}>
                        {node.title}
                        <Icon symbol="plus" />
                      </h4>
                    )}
                  </Link>
                </div>

              )

            })}

            </>

          )}

        </div>
      </div>
    </div>
  );
}

export default ColorPanel;
