import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";

import PageHeader from "../components/page-header-product";
import FlexibleContent from "../components/flexible-content";
import ProductDetails from "../components/product-details";
import ProductObjectsList from "../components/product-objects-list";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query ProductTemplateQuery($id: String!) {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      notificationBar {
        link {
          url
        }
      }
    }
    fabrics: allSanityFabric(
      sort: { fields: [orderRank], order: ASC }
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          image {
            ...SanityImage
            alt
          }
          parentItem {
            id
          }
          _rawTechnicalDetails
        }
      }
    }
    leathers: allSanityLeather(
      sort: { fields: [orderRank], order: ASC }
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          image {
            ...SanityImage
            alt
          }
          parentItem {
            id
          }
          _rawTechnicalDetails
        }
      }
    }
    outdoorFabrics: allSanityOutdoorFabric(
      sort: { fields: [orderRank], order: ASC }
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          image {
            ...SanityImage
            alt
          }
          parentItem {
            id
          }
          _rawTechnicalDetails
        }
      }
    }
    topFinishes: allSanityTopFinish(
      sort: { fields: [orderRank], order: ASC }
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          image {
            ...SanityImage
            alt
          }
          parentItem {
            id
          }
          _rawTechnicalDetails
        }
      }
    }
    glassFinishes: allSanityGlassFinish(
      sort: { fields: [orderRank], order: ASC }
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          image {
            ...SanityImage
            alt
          }
          parentItem {
            id
          }
          _rawTechnicalDetails
        }
      }
    }
    seatFinishes: allSanitySeatFinish(
      sort: { fields: [orderRank], order: ASC }
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          image {
            ...SanityImage
            alt
          }
          parentItem {
            id
          }
          _rawTechnicalDetails
        }
      }
    }
    webbingFinishes: allSanityWebbingFinish(
      sort: { fields: [orderRank], order: ASC }
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          image {
            ...SanityImage
            alt
          }
          parentItem {
            id
          }
          _rawTechnicalDetails
        }
      }
    }
    drawerFinishes: allSanityDrawerFinish(
      sort: { fields: [orderRank], order: ASC }
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          image {
            ...SanityImage
            alt
          }
          parentItem {
            id
          }
          _rawTechnicalDetails
        }
      }
    }
    drawerBaseFinishes: allSanityDrawerBaseFinish(
      sort: { fields: [orderRank], order: ASC }
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          image {
            ...SanityImage
            alt
          }
          parentItem {
            id
          }
          _rawTechnicalDetails
        }
      }
    }
    frameFinishes: allSanityFrameFinish(
      sort: { fields: [orderRank], order: ASC }
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          image {
            ...SanityImage
            alt
          }
          parentItem {
            id
          }
          _rawTechnicalDetails
        }
      }
    }
    structureFinishes: allSanityStructureFinish(
      sort: { fields: [orderRank], order: ASC }
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          image {
            ...SanityImage
            alt
          }
          parentItem {
            id
          }
          _rawTechnicalDetails
        }
      }
    }
    legFinishes: allSanityLegFinish(
      sort: { fields: [orderRank], order: ASC }
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          image {
            ...SanityImage
            alt
          }
          parentItem {
            id
          }
          _rawTechnicalDetails
        }
      }
    }
    baseFinishes: allSanityBaseFinish(
      sort: { fields: [orderRank], order: ASC }
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          image {
            ...SanityImage
            alt
          }
          parentItem {
            id
          }
          _rawTechnicalDetails
        }
      }
    }
    finBaseFinishes: allSanityFinBaseFinish(
      sort: { fields: [orderRank], order: ASC }
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          image {
            ...SanityImage
            alt
          }
          parentItem {
            id
          }
          _rawTechnicalDetails
        }
      }
    }
    feetFinishes: allSanityFeetFinish(
      sort: { fields: [orderRank], order: ASC }
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          image {
            ...SanityImage
            alt
          }
          parentItem {
            id
          }
          _rawTechnicalDetails
        }
      }
    }
    product: sanityProduct(id: { eq: $id }) {
      id
      title
      slug {
        current
      }
      description
      featuredImage {
        ...SanityImage
        alt
      }
      productHero {
        ...SanityImage
        alt
      }
      mobileHero {
        ...SanityImage
        alt
      }
      whiteHeaderElements
      enableConfigurator
      enableDrawings
      enableCompositions
      enableGallery
      enableFabrics
      enableLeathers
      enableOutdoorFabrics
      enableTopFinishes
      enableGlassFinishes
      enableSeatFinishes
      enableWebbingFinishes
      enableDrawerFinishes
      enableDrawerBaseFinishes
      enableFrameFinishes
      enableStructureFinishes
      enableLegFinishes
      enableBaseFinishes
      enableFinBaseFinishes
      enableFeetFinishes
      excludedSwatches {
        fabrics {
          _key
          fabric {
            id
          }
        }
        leathers {
          _key
          leather {
            id
          }
        }
        outdoorFabrics {
          _key
          outdoorFabric {
            id
          }
        }
        topFinishes {
          _key
          topFinish {
            id
          }
        }
        glassFinishes {
          _key
          glassFinish {
            id
          }
        }
        seatFinishes {
          _key
          seatFinish {
            id
          }
        }
        webbingFinishes {
          _key
          webbingFinish {
            id
          }
        }
        drawerFinishes {
          _key
          drawerFinish {
            id
          }
        }
        drawerBaseFinishes {
          _key
          drawerBaseFinish {
            id
          }
        }
        frameFinishes {
          _key
          frameFinish {
            id
          }
        }
        structureFinishes {
          _key
          structureFinish {
            id
          }
        }
        legFinishes {
          _key
          legFinish {
            id
          }
        }
        baseFinishes {
          _key
          baseFinish {
            id
          }
        }
        finBaseFinishes {
          _key
          finBaseFinish {
            id
          }
        }
        feetFinishes {
          _key
          feetFinish {
            id
          }
        }
      }
      flexibleContent {
        ... on SanityFcContentBlock {
          _key
          _type
          alignRight
          _rawText
        }
        ... on SanityFcFeaturedProducts {
          _key
          _type
          fillerImage {
            ...SanityImage
            alt
          }
          fillAlignRight
          featuredProducts {
            _key
            product {
              title
              slug {
                current
              }
              description
              featuredImage {
                ...SanityImage
                alt
              }
              secondaryFeaturedImage {
                ...SanityImage
                alt
              }
            }
          }
        }
        ... on SanityFc2ColumnImage {
          _key
          _type
          paddedLeft
          paddedRight
          imageLeft {
            ...SanityImage
            alt
            caption
          }
          imageRight {
            ...SanityImage
            alt
            caption
          }
        }
        ... on SanityFcSlider {
          _key
          _type
          slides {
            _key
            ...SanityImage
            alt
            caption
          }
        }
        ... on SanityFcVideo {
          _key
          _type
          url
          alignment
          thumbnail {
            ...SanityImage
            alt
          }
        }
      }
      _rawTechnicalDetails
      configuratorDescription
      configuratorLink {
        blank
        text
        url
      }
      downloads {
        _key
        download {
          title
          file {
            asset {
              url
              originalFilename
            }
          }
        }
      }
      drawings {
        _key
        thumbnail {
          ...SanityImage
          alt
        }
        name
        partNumber
        _rawMeasurements
      }
      compositions {
        _key
        thumbnail {
          ...SanityImage
          alt
        }
        name
        partNumber
        _rawMeasurements
      }
      relatedProducts {
        _key
        product {
          title
          slug {
            current
          }
          description
          featuredImage {
            ...SanityImage
            alt
          }
          secondaryFeaturedImage {
            ...SanityImage
            alt
          }
        }
      }
    }
  }
`;

const ProductTemplate = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }
  
  const site = (data || {}).site;
  const product = (data || {}).product;
  const fabrics = (data || {}).fabrics;
  const leathers = (data || {}).leathers;
  const outdoorFabrics = (data || {}).outdoorFabrics;
  const topFinishes = (data || {}).topFinishes;
  const glassFinishes = (data || {}).glassFinishes;
  const seatFinishes = (data || {}).seatFinishes;
  const webbingFinishes = (data || {}).webbingFinishes;
  const drawerFinishes = (data || {}).drawerFinishes;
  const drawerBaseFinishes = (data || {}).drawerBaseFinishes;
  const frameFinishes = (data || {}).frameFinishes;
  const structureFinishes = (data || {}).structureFinishes;
  const legFinishes = (data || {}).legFinishes;
  const baseFinishes = (data || {}).baseFinishes;
  const finBaseFinishes = (data || {}).finBaseFinishes;
  const feetFinishes = (data || {}).feetFinishes;

  return (
    <Layout>
      <Seo
        title={product.title}
        description={product.description}
        image={product.featuredImage}
      />

      {(
        (product.productHero && product.productHero.asset) || 
        (product.mobileHero && product.mobileHero.asset) || 
        product.title || 
        product.description
      ) && (
        <PageHeader 
          productHero={product.productHero} 
          mobileHero={product.mobileHero} 
          title={product.title} 
          description={product.description} 
          whiteHeaderElements={product.whiteHeaderElements} 
          enableGallery={product.enableGallery} 
          technicalDetails={product._rawTechnicalDetails} 
          notificationBar={site.notificationBar}
        />
      )}

      {product.flexibleContent && (<FlexibleContent items={product.flexibleContent} enableGallery={product.enableGallery} />)}
      
      {(
        product._rawTechnicalDetails || 
        product.enableConfigurator || 
        product.enableDrawings || 
        product.enableCompositions || 
        product.enableFabrics || 
        product.enableLeathers || 
        product.enableOutdoorFabrics || 
        product.enableTopFinishes || 
        product.enableGlassFinishes || 
        product.enableSeatFinishes || 
        product.enableWebbingFinishes || 
        product.enableDrawerFinishes || 
        product.enableDrawerBaseFinishes || 
        product.enableFrameFinishes || 
        product.enableStructureFinishes || 
        product.enableLegFinishes || 
        product.enableBaseFinishes || 
        product.enableFinBaseFinishes || 
        product.enableFeetFinishes || 
        (product.downloads && product.downloads.length > 0)
      ) && (
        <ProductDetails 
          technicalDetails={product._rawTechnicalDetails} 
          configuratorDescription={product.configuratorDescription}
          configuratorLink={product.configuratorLink}
          fabrics={fabrics}
          leathers={leathers}
          outdoorFabrics={outdoorFabrics}
          topFinishes={topFinishes}
          glassFinishes={glassFinishes}
          seatFinishes={seatFinishes}
          webbingFinishes={webbingFinishes}
          drawerFinishes={drawerFinishes}
          drawerBaseFinishes={drawerBaseFinishes}
          frameFinishes={frameFinishes}
          structureFinishes={structureFinishes}
          legFinishes={legFinishes}
          baseFinishes={baseFinishes}
          finBaseFinishes={finBaseFinishes}
          feetFinishes={feetFinishes}
          downloads={product.downloads}
          drawings={product.drawings}
          compositions={product.compositions}
          enableConfigurator={product.enableConfigurator} 
          enableDrawings={product.enableDrawings} 
          enableCompositions={product.enableCompositions} 
          enableFabrics={product.enableFabrics} 
          enableLeathers={product.enableLeathers} 
          enableOutdoorFabrics={product.enableOutdoorFabrics} 
          enableTopFinishes={product.enableTopFinishes} 
          enableGlassFinishes={product.enableGlassFinishes} 
          enableSeatFinishes={product.enableSeatFinishes} 
          enableWebbingFinishes={product.enableWebbingFinishes} 
          enableDrawerFinishes={product.enableDrawerFinishes} 
          enableDrawerBaseFinishes={product.enableDrawerBaseFinishes} 
          enableFrameFinishes={product.enableFrameFinishes} 
          enableStructureFinishes={product.enableStructureFinishes} 
          enableLegFinishes={product.enableLegFinishes} 
          enableBaseFinishes={product.enableBaseFinishes} 
          enableFinBaseFinishes={product.enableFinBaseFinishes} 
          enableFeetFinishes={product.enableFeetFinishes} 
          excludedFabrics={product.excludedSwatches?.fabrics}
          excludedLeathers={product.excludedSwatches?.leathers}
          excludedOutdoorFabrics={product.excludedSwatches?.outdoorFabrics}
          excludedTopFinishes={product.excludedSwatches?.topFinishes}
          excludedGlassFinishes={product.excludedSwatches?.glassFinishes}
          excludedSeatFinishes={product.excludedSwatches?.seatFinishes}
          excludedWebbingFinishes={product.excludedSwatches?.webbingFinishes}
          excludedDrawerFinishes={product.excludedSwatches?.drawerFinishes}
          excludedDrawerBaseFinishes={product.excludedSwatches?.drawerBaseFinishes}
          excludedFrameFinishes={product.excludedSwatches?.frameFinishes}
          excludedStructureFinishes={product.excludedSwatches?.structureFinishes}
          excludedLegFinishes={product.excludedSwatches?.legFinishes}
          excludedBaseFinishes={product.excludedSwatches?.baseFinishes}
          excludedFinBaseFinishes={product.excludedSwatches?.finBaseFinishes}
          excludedFeetFinishes={product.excludedSwatches?.feetFinishes}
          notificationBar={site.notificationBar}
        />
      )}

      {product.relatedProducts && product.relatedProducts.length > 0 && (<ProductObjectsList products={product.relatedProducts} title="Related" />)}
    </Layout>
  );
};

export default ProductTemplate;
