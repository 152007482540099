import React from "react";
import { Link } from "gatsby";
import { cn } from "../lib/helpers";
import PortableText from "./portableText";
import Icon from "./icon";

import * as styles from "../styles/components/product-configurator.module.css";

const Configurator = (props) => {

  const {
    description,
    link
  } = props;

  return (
    <div className={styles.root}>
      <div className="grid">
        <div className="col-12 col-md-6">
          {description && (<p>{description}</p>)}
          {link && link.url && (
            <>
              {link.blank ? (
                <a href={link.url} target="_blank" rel="noreferrer" className="external--link">
                  <Icon symbol="external" />
                  {link.text}
                </a> 
              ) : (
                <Link to={link.url} className="external--link">
                  <Icon symbol="external" />
                  {link.text}
                </Link>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Configurator;
