import React, { useState } from "react";
import { cn } from "../lib/helpers";
import AnimateHeight from 'react-animate-height';
import TechnicalDetails from "./product-technical-details";
import Configurator from "./product-configurator";
import ColorPanel from "./product-color-panel";
import LineDrawings from "./product-line-drawings";
import Compositions from "./product-compositions";
import Documentation from "./product-documentation";

import * as styles from "../styles/components/product-details.module.css";

const ProductDetails = (props) => {

  const {
    technicalDetails,
    configuratorDescription,
    configuratorLink,
    fabrics,
    leathers,
    outdoorFabrics,
    topFinishes,
    glassFinishes,
    seatFinishes,
    webbingFinishes,
    drawerFinishes,
    drawerBaseFinishes,
    frameFinishes,
    structureFinishes,
    legFinishes,
    baseFinishes,
    finBaseFinishes,
    feetFinishes,
    downloads,
    drawings,
    compositions,
    enableConfigurator,
    enableDrawings,
    enableCompositions,
    enableFabrics,
    enableLeathers,
    enableOutdoorFabrics,
    enableTopFinishes,
    enableGlassFinishes,
    enableSeatFinishes,
    enableWebbingFinishes,
    enableDrawerFinishes,
    enableDrawerBaseFinishes,
    enableFrameFinishes,
    enableStructureFinishes,
    enableLegFinishes,
    enableBaseFinishes,
    enableFinBaseFinishes,
    enableFeetFinishes,
    excludedFabrics,
    excludedLeathers,
    excludedOutdoorFabrics,
    excludedTopFinishes,
    excludedGlassFinishes,
    excludedSeatFinishes,
    excludedWebbingFinishes,
    excludedDrawerFinishes,
    excludedDrawerBaseFinishes,
    excludedFrameFinishes,
    excludedStructureFinishes,
    excludedLegFinishes,
    excludedBaseFinishes,
    excludedFinBaseFinishes,
    excludedFeetFinishes,
    notificationBar
  } = props;

  const duration = 600;
  const ease = "cubic-bezier(0.86, 0, 0.07, 1)";

  const [heightTechnicalDetails, setHeightTechnicalDetails] = useState('auto');
  const [heightConfigurator, setHeightConfigurator] = useState(0);
  const [heightFabrics, setHeightFabrics] = useState(0);
  const [heightLeathers, setHeightLeathers] = useState(0);
  const [heightOutdoorFabrics, setHeightOutdoorFabrics] = useState(0);
  const [heightTopFinishes, setHeightTopFinishes] = useState(0);
  const [heightGlassFinishes, setHeightGlassFinishes] = useState(0);
  const [heightSeatFinishes, setHeightSeatFinishes] = useState(0);
  const [heightWebbingFinishes, setHeightWebbingFinishes] = useState(0);
  const [heightDrawerFinishes, setHeightDrawerFinishes] = useState(0);
  const [heightDrawerBaseFinishes, setHeightDrawerBaseFinishes] = useState(0);
  const [heightFrameFinishes, setHeightFrameFinishes] = useState(0);
  const [heightStructureFinishes, setHeightStructureFinishes] = useState(0);
  const [heightLegFinishes, setHeightLegFinishes] = useState(0);
  const [heightBaseFinishes, setHeightBaseFinishes] = useState(0);
  const [heightFinBaseFinishes, setHeightFinBaseFinishes] = useState(0);
  const [heightFeetFinishes, setHeightFeetFinishes] = useState(0);
  const [heightLineDrawings, setHeightLineDrawings] = useState(0);
  const [heightCompositions, setHeightCompositions] = useState(0);
  const [heightDocumentation, setHeightDocumentation] = useState(0);

  function handleClosePanel(e) {
    setTimeout(function() {
      const items = e.getElementsByClassName('cp-active');
      Array.from(items).forEach(function(el){
        el.classList.remove('cp-active');
      });
    }, 600);
  }

  function handleToggleAccordion(e) {
    const acWrap = e.target.parentElement;

    if(acWrap.classList.contains(styles.active)) {
      acWrap.classList.remove(styles.active);
      handleClosePanel(acWrap);
    }
    else {
      const items = document.getElementsByClassName(styles.active);
      Array.from(items).forEach(function(el){
        el.classList.remove(styles.active);
        handleClosePanel(el);
      });
      acWrap.classList.add(styles.active);
    }
  }

  function handleToggleTechnicalDetailsAc(e) {
    handleToggleAccordion(e);

    setHeightConfigurator(0);
    setHeightFabrics(0);
    setHeightLeathers(0);
    setHeightOutdoorFabrics(0);
    setHeightTopFinishes(0);
    setHeightGlassFinishes(0);
    setHeightSeatFinishes(0);
    setHeightWebbingFinishes(0);
    setHeightDrawerFinishes(0);
    setHeightDrawerBaseFinishes(0);
    setHeightFrameFinishes(0);
    setHeightStructureFinishes(0);
    setHeightLegFinishes(0);
    setHeightBaseFinishes(0);
    setHeightFinBaseFinishes(0);
    setHeightFeetFinishes(0);
    setHeightLineDrawings(0);
    setHeightCompositions(0);
    setHeightDocumentation(0);

    setHeightTechnicalDetails(heightTechnicalDetails === 0 ? 'auto' : 0);
  }

  function handleToggleConfiguratorAc(e) {
    handleToggleAccordion(e);

    setHeightTechnicalDetails(0);
    setHeightFabrics(0);
    setHeightLeathers(0);
    setHeightOutdoorFabrics(0);
    setHeightTopFinishes(0);
    setHeightGlassFinishes(0);
    setHeightSeatFinishes(0);
    setHeightWebbingFinishes(0);
    setHeightDrawerFinishes(0);
    setHeightDrawerBaseFinishes(0);
    setHeightFrameFinishes(0);
    setHeightStructureFinishes(0);
    setHeightLegFinishes(0);
    setHeightBaseFinishes(0);
    setHeightFinBaseFinishes(0);
    setHeightFeetFinishes(0);
    setHeightLineDrawings(0);
    setHeightCompositions(0);
    setHeightDocumentation(0);
    
    setHeightConfigurator(heightConfigurator === 0 ? 'auto' : 0);
  }

  function handleToggleFabricsAc(e) {
    handleToggleAccordion(e);

    setHeightTechnicalDetails(0);
    setHeightConfigurator(0);
    setHeightLeathers(0);
    setHeightOutdoorFabrics(0);
    setHeightTopFinishes(0);
    setHeightGlassFinishes(0);
    setHeightSeatFinishes(0);
    setHeightWebbingFinishes(0);
    setHeightDrawerFinishes(0);
    setHeightDrawerBaseFinishes(0);
    setHeightFrameFinishes(0);
    setHeightStructureFinishes(0);
    setHeightLegFinishes(0);
    setHeightBaseFinishes(0);
    setHeightFinBaseFinishes(0);
    setHeightFeetFinishes(0);
    setHeightLineDrawings(0);
    setHeightCompositions(0);
    setHeightDocumentation(0);
    
    setHeightFabrics(heightFabrics === 0 ? 'auto' : 0);
  }

  function handleToggleLeathersAc(e) {
    handleToggleAccordion(e);

    setHeightTechnicalDetails(0);
    setHeightConfigurator(0);
    setHeightFabrics(0);
    setHeightOutdoorFabrics(0);
    setHeightTopFinishes(0);
    setHeightGlassFinishes(0);
    setHeightSeatFinishes(0);
    setHeightWebbingFinishes(0);
    setHeightDrawerFinishes(0);
    setHeightDrawerBaseFinishes(0);
    setHeightFrameFinishes(0);
    setHeightStructureFinishes(0);
    setHeightLegFinishes(0);
    setHeightBaseFinishes(0);
    setHeightFinBaseFinishes(0);
    setHeightFeetFinishes(0);
    setHeightLineDrawings(0);
    setHeightCompositions(0);
    setHeightDocumentation(0);
    
    setHeightLeathers(heightLeathers === 0 ? 'auto' : 0);
  }

  function handleToggleOutdoorFabricsAc(e) {
    handleToggleAccordion(e);

    setHeightTechnicalDetails(0);
    setHeightConfigurator(0);
    setHeightFabrics(0);
    setHeightLeathers(0);
    setHeightTopFinishes(0);
    setHeightGlassFinishes(0);
    setHeightSeatFinishes(0);
    setHeightWebbingFinishes(0);
    setHeightDrawerFinishes(0);
    setHeightDrawerBaseFinishes(0);
    setHeightFrameFinishes(0);
    setHeightStructureFinishes(0);
    setHeightLegFinishes(0);
    setHeightBaseFinishes(0);
    setHeightFinBaseFinishes(0);
    setHeightFeetFinishes(0);
    setHeightLineDrawings(0);
    setHeightCompositions(0);
    setHeightDocumentation(0);
    
    setHeightOutdoorFabrics(heightOutdoorFabrics === 0 ? 'auto' : 0);
  }

  function handleToggleTopFinishesAc(e) {
    handleToggleAccordion(e);

    setHeightTechnicalDetails(0);
    setHeightConfigurator(0);
    setHeightFabrics(0);
    setHeightLeathers(0);
    setHeightOutdoorFabrics(0);
    setHeightGlassFinishes(0);
    setHeightSeatFinishes(0);
    setHeightWebbingFinishes(0);
    setHeightDrawerFinishes(0);
    setHeightDrawerBaseFinishes(0);
    setHeightFrameFinishes(0);
    setHeightStructureFinishes(0);
    setHeightLegFinishes(0);
    setHeightBaseFinishes(0);
    setHeightFinBaseFinishes(0);
    setHeightFeetFinishes(0);
    setHeightLineDrawings(0);
    setHeightCompositions(0);
    setHeightDocumentation(0);
    
    setHeightTopFinishes(heightTopFinishes === 0 ? 'auto' : 0);
  }

  function handleToggleGlassFinishesAc(e) {
    handleToggleAccordion(e);

    setHeightTechnicalDetails(0);
    setHeightConfigurator(0);
    setHeightFabrics(0);
    setHeightLeathers(0);
    setHeightOutdoorFabrics(0);
    setHeightTopFinishes(0);
    setHeightSeatFinishes(0);
    setHeightWebbingFinishes(0);
    setHeightDrawerFinishes(0);
    setHeightDrawerBaseFinishes(0);
    setHeightFrameFinishes(0);
    setHeightStructureFinishes(0);
    setHeightLegFinishes(0);
    setHeightBaseFinishes(0);
    setHeightFinBaseFinishes(0);
    setHeightFeetFinishes(0);
    setHeightLineDrawings(0);
    setHeightCompositions(0);
    setHeightDocumentation(0);
    
    setHeightGlassFinishes(heightGlassFinishes === 0 ? 'auto' : 0);
  }

  function handleToggleSeatFinishesAc(e) {
    handleToggleAccordion(e);

    setHeightTechnicalDetails(0);
    setHeightConfigurator(0);
    setHeightFabrics(0);
    setHeightLeathers(0);
    setHeightOutdoorFabrics(0);
    setHeightTopFinishes(0);
    setHeightGlassFinishes(0);
    setHeightWebbingFinishes(0);
    setHeightDrawerFinishes(0);
    setHeightDrawerBaseFinishes(0);
    setHeightFrameFinishes(0);
    setHeightStructureFinishes(0);
    setHeightLegFinishes(0);
    setHeightBaseFinishes(0);
    setHeightFinBaseFinishes(0);
    setHeightFeetFinishes(0);
    setHeightLineDrawings(0);
    setHeightCompositions(0);
    setHeightDocumentation(0);
    
    setHeightSeatFinishes(heightSeatFinishes === 0 ? 'auto' : 0);
  }

  function handleToggleWebbingFinishesAc(e) {
    handleToggleAccordion(e);

    setHeightTechnicalDetails(0);
    setHeightConfigurator(0);
    setHeightFabrics(0);
    setHeightLeathers(0);
    setHeightOutdoorFabrics(0);
    setHeightTopFinishes(0);
    setHeightGlassFinishes(0);
    setHeightSeatFinishes(0);
    setHeightDrawerFinishes(0);
    setHeightDrawerBaseFinishes(0);
    setHeightFrameFinishes(0);
    setHeightStructureFinishes(0);
    setHeightLegFinishes(0);
    setHeightBaseFinishes(0);
    setHeightFinBaseFinishes(0);
    setHeightFeetFinishes(0);
    setHeightLineDrawings(0);
    setHeightCompositions(0);
    setHeightDocumentation(0);
    
    setHeightWebbingFinishes(heightWebbingFinishes === 0 ? 'auto' : 0);
  }

  function handleToggleDrawerFinishesAc(e) {
    handleToggleAccordion(e);

    setHeightTechnicalDetails(0);
    setHeightConfigurator(0);
    setHeightFabrics(0);
    setHeightLeathers(0);
    setHeightOutdoorFabrics(0);
    setHeightTopFinishes(0);
    setHeightGlassFinishes(0);
    setHeightSeatFinishes(0);
    setHeightDrawerBaseFinishes(0);
    setHeightWebbingFinishes(0);
    setHeightFrameFinishes(0);
    setHeightStructureFinishes(0);
    setHeightLegFinishes(0);
    setHeightBaseFinishes(0);
    setHeightFinBaseFinishes(0);
    setHeightFeetFinishes(0);
    setHeightLineDrawings(0);
    setHeightCompositions(0);
    setHeightDocumentation(0);
    
    setHeightDrawerFinishes(heightDrawerFinishes === 0 ? 'auto' : 0);
  }

  function handleToggleDrawerBaseFinishesAc(e) {
    handleToggleAccordion(e);

    setHeightTechnicalDetails(0);
    setHeightConfigurator(0);
    setHeightFabrics(0);
    setHeightLeathers(0);
    setHeightOutdoorFabrics(0);
    setHeightTopFinishes(0);
    setHeightGlassFinishes(0);
    setHeightSeatFinishes(0);
    setHeightDrawerFinishes(0);
    setHeightWebbingFinishes(0);
    setHeightFrameFinishes(0);
    setHeightStructureFinishes(0);
    setHeightLegFinishes(0);
    setHeightBaseFinishes(0);
    setHeightFinBaseFinishes(0);
    setHeightFeetFinishes(0);
    setHeightLineDrawings(0);
    setHeightCompositions(0);
    setHeightDocumentation(0);
    
    setHeightDrawerBaseFinishes(heightDrawerBaseFinishes === 0 ? 'auto' : 0);
  }

  function handleToggleFrameFinishesAc(e) {
    handleToggleAccordion(e);

    setHeightTechnicalDetails(0);
    setHeightConfigurator(0);
    setHeightFabrics(0);
    setHeightLeathers(0);
    setHeightOutdoorFabrics(0);
    setHeightTopFinishes(0);
    setHeightGlassFinishes(0);
    setHeightSeatFinishes(0);
    setHeightWebbingFinishes(0);
    setHeightDrawerFinishes(0);
    setHeightDrawerBaseFinishes(0);
    setHeightStructureFinishes(0);
    setHeightLegFinishes(0);
    setHeightBaseFinishes(0);
    setHeightFinBaseFinishes(0);
    setHeightFeetFinishes(0);
    setHeightLineDrawings(0);
    setHeightCompositions(0);
    setHeightDocumentation(0);
    
    setHeightFrameFinishes(heightFrameFinishes === 0 ? 'auto' : 0);
  }

  function handleToggleStructureFinishesAc(e) {
    handleToggleAccordion(e);

    setHeightTechnicalDetails(0);
    setHeightConfigurator(0);
    setHeightFabrics(0);
    setHeightLeathers(0);
    setHeightOutdoorFabrics(0);
    setHeightTopFinishes(0);
    setHeightGlassFinishes(0);
    setHeightSeatFinishes(0);
    setHeightWebbingFinishes(0);
    setHeightDrawerFinishes(0);
    setHeightDrawerBaseFinishes(0);
    setHeightFrameFinishes(0);
    setHeightLegFinishes(0);
    setHeightBaseFinishes(0);
    setHeightFinBaseFinishes(0);
    setHeightFeetFinishes(0);
    setHeightLineDrawings(0);
    setHeightCompositions(0);
    setHeightDocumentation(0);
    
    setHeightStructureFinishes(heightStructureFinishes === 0 ? 'auto' : 0);
  }

  function handleToggleLegFinishesAc(e) {
    handleToggleAccordion(e);

    setHeightTechnicalDetails(0);
    setHeightConfigurator(0);
    setHeightFabrics(0);
    setHeightLeathers(0);
    setHeightOutdoorFabrics(0);
    setHeightTopFinishes(0);
    setHeightGlassFinishes(0);
    setHeightSeatFinishes(0);
    setHeightWebbingFinishes(0);
    setHeightDrawerFinishes(0);
    setHeightDrawerBaseFinishes(0);
    setHeightFrameFinishes(0);
    setHeightStructureFinishes(0);
    setHeightBaseFinishes(0);
    setHeightFinBaseFinishes(0);
    setHeightFeetFinishes(0);
    setHeightLineDrawings(0);
    setHeightCompositions(0);
    setHeightDocumentation(0);
    
    setHeightLegFinishes(heightLegFinishes === 0 ? 'auto' : 0);
  }

  function handleToggleBaseFinishesAc(e) {
    handleToggleAccordion(e);

    setHeightTechnicalDetails(0);
    setHeightConfigurator(0);
    setHeightFabrics(0);
    setHeightLeathers(0);
    setHeightOutdoorFabrics(0);
    setHeightTopFinishes(0);
    setHeightGlassFinishes(0);
    setHeightSeatFinishes(0);
    setHeightWebbingFinishes(0);
    setHeightDrawerFinishes(0);
    setHeightDrawerBaseFinishes(0);
    setHeightFrameFinishes(0);
    setHeightStructureFinishes(0);
    setHeightLegFinishes(0);
    setHeightFinBaseFinishes(0);
    setHeightFeetFinishes(0);
    setHeightLineDrawings(0);
    setHeightCompositions(0);
    setHeightDocumentation(0);
    
    setHeightBaseFinishes(heightBaseFinishes === 0 ? 'auto' : 0);
  }

  function handleToggleFinBaseFinishesAc(e) {
    handleToggleAccordion(e);

    setHeightTechnicalDetails(0);
    setHeightConfigurator(0);
    setHeightFabrics(0);
    setHeightLeathers(0);
    setHeightOutdoorFabrics(0);
    setHeightTopFinishes(0);
    setHeightGlassFinishes(0);
    setHeightSeatFinishes(0);
    setHeightWebbingFinishes(0);
    setHeightDrawerFinishes(0);
    setHeightDrawerBaseFinishes(0);
    setHeightFrameFinishes(0);
    setHeightStructureFinishes(0);
    setHeightLegFinishes(0);
    setHeightBaseFinishes(0);
    setHeightFeetFinishes(0);
    setHeightLineDrawings(0);
    setHeightCompositions(0);
    setHeightDocumentation(0);
    
    setHeightFinBaseFinishes(heightFinBaseFinishes === 0 ? 'auto' : 0);
  }

  function handleToggleFeetFinishesAc(e) {
    handleToggleAccordion(e);

    setHeightTechnicalDetails(0);
    setHeightConfigurator(0);
    setHeightFabrics(0);
    setHeightLeathers(0);
    setHeightOutdoorFabrics(0);
    setHeightTopFinishes(0);
    setHeightGlassFinishes(0);
    setHeightSeatFinishes(0);
    setHeightWebbingFinishes(0);
    setHeightDrawerFinishes(0);
    setHeightDrawerBaseFinishes(0);
    setHeightFrameFinishes(0);
    setHeightStructureFinishes(0);
    setHeightLegFinishes(0);
    setHeightBaseFinishes(0);
    setHeightFinBaseFinishes(0);
    setHeightLineDrawings(0);
    setHeightCompositions(0);
    setHeightDocumentation(0);
    
    setHeightFeetFinishes(heightFeetFinishes === 0 ? 'auto' : 0);
  }

  function handleToggleLineDrawingsAc(e) {
    handleToggleAccordion(e);

    setHeightTechnicalDetails(0);
    setHeightConfigurator(0);
    setHeightFabrics(0);
    setHeightLeathers(0);
    setHeightOutdoorFabrics(0);
    setHeightTopFinishes(0);
    setHeightGlassFinishes(0);
    setHeightSeatFinishes(0);
    setHeightWebbingFinishes(0);
    setHeightDrawerFinishes(0);
    setHeightDrawerBaseFinishes(0);
    setHeightFrameFinishes(0);
    setHeightStructureFinishes(0);
    setHeightLegFinishes(0);
    setHeightBaseFinishes(0);
    setHeightFinBaseFinishes(0);
    setHeightCompositions(0);
    setHeightFeetFinishes(0);
    setHeightDocumentation(0);
    
    setHeightLineDrawings(heightLineDrawings === 0 ? 'auto' : 0);
  }

  function handleToggleCompositionsAc(e) {
    handleToggleAccordion(e);

    setHeightTechnicalDetails(0);
    setHeightConfigurator(0);
    setHeightFabrics(0);
    setHeightLeathers(0);
    setHeightOutdoorFabrics(0);
    setHeightTopFinishes(0);
    setHeightGlassFinishes(0);
    setHeightSeatFinishes(0);
    setHeightWebbingFinishes(0);
    setHeightDrawerFinishes(0);
    setHeightDrawerBaseFinishes(0);
    setHeightFrameFinishes(0);
    setHeightStructureFinishes(0);
    setHeightLegFinishes(0);
    setHeightBaseFinishes(0);
    setHeightFinBaseFinishes(0);
    setHeightLineDrawings(0);
    setHeightFeetFinishes(0);
    setHeightDocumentation(0);
    
    setHeightCompositions(heightCompositions === 0 ? 'auto' : 0);
  }

  function handleToggleDocumentationAc(e) {
    handleToggleAccordion(e);

    setHeightTechnicalDetails(0);
    setHeightConfigurator(0);
    setHeightFabrics(0);
    setHeightLeathers(0);
    setHeightOutdoorFabrics(0);
    setHeightTopFinishes(0);
    setHeightGlassFinishes(0);
    setHeightSeatFinishes(0);
    setHeightWebbingFinishes(0);
    setHeightDrawerFinishes(0);
    setHeightDrawerBaseFinishes(0);
    setHeightFrameFinishes(0);
    setHeightStructureFinishes(0);
    setHeightLegFinishes(0);
    setHeightBaseFinishes(0);
    setHeightFinBaseFinishes(0);
    setHeightFeetFinishes(0);
    setHeightLineDrawings(0);
    setHeightCompositions(0);
    
    setHeightDocumentation(heightDocumentation === 0 ? 'auto' : 0);
  }

  return (
    <div className={styles.root}>
      {technicalDetails && (
        <div className={cn(styles.accordion, styles.active)} id="technical-details">
          <h3 className={styles.toggle} onClick={handleToggleTechnicalDetailsAc}>Technical Details</h3>
          <AnimateHeight
            duration={duration}
            easing={ease}
            height={heightTechnicalDetails}
          >
            <div className={styles.inner}>
              <TechnicalDetails technicalDetails={technicalDetails} configuratorLink={configuratorLink} />
            </div>
          </AnimateHeight>
        </div>
      )}
      {enableConfigurator && (
        <div className={styles.accordion}>
          <h3 className={styles.toggle} onClick={handleToggleConfiguratorAc}>Configurator</h3>
          <AnimateHeight
            duration={duration}
            easing={ease}
            height={heightConfigurator}
          >
            <div className={styles.inner}>
              <Configurator description={configuratorDescription} link={configuratorLink} />
            </div>
          </AnimateHeight>
        </div>
      )}
      {enableFabrics && (
        <div className={styles.accordion}>
          <h3 className={styles.toggle} onClick={handleToggleFabricsAc}>Fabrics</h3>
          <AnimateHeight
            duration={duration}
            easing={ease}
            height={heightFabrics}
          >
            <div className={styles.inner}>
              <ColorPanel 
                type={(excludedFabrics && excludedFabrics.length > 0) ? fabrics.edges.filter(post => (!excludedFabrics.map(({fabric}) => fabric.id).includes(post.node.id))) : fabrics.edges} 
                typeParents={(excludedFabrics && excludedFabrics.length > 0) ? fabrics.edges.filter(post => (!post.node.parentItem && !excludedFabrics.map(({fabric}) => fabric.id).includes(post.node.id))) : fabrics.edges.filter(post => (!post.node.parentItem))} 
                typeSlug="fabrics"
                notificationBar={notificationBar} />
            </div>
          </AnimateHeight>
        </div>
      )}
      {enableLeathers && (
        <div className={styles.accordion}>
          <h3 className={styles.toggle} onClick={handleToggleLeathersAc}>Leathers</h3>
          <AnimateHeight
            duration={duration}
            easing={ease}
            height={heightLeathers}
          >
            <div className={styles.inner}>
              <ColorPanel 
                type={(excludedLeathers && excludedLeathers.length > 0) ? leathers.edges.filter(post => (!excludedLeathers.map(({leather}) => leather.id).includes(post.node.id))) : leathers.edges} 
                typeParents={(excludedLeathers && excludedLeathers.length > 0) ? leathers.edges.filter(post => (!post.node.parentItem && !excludedLeathers.map(({leather}) => leather.id).includes(post.node.id))) : leathers.edges.filter(post => (!post.node.parentItem))} 
                typeSlug="leathers"
                notificationBar={notificationBar} />
            </div>
          </AnimateHeight>
        </div>
      )}
      {enableOutdoorFabrics && (
        <div className={styles.accordion}>
          <h3 className={styles.toggle} onClick={handleToggleOutdoorFabricsAc}>Outdoor Fabrics</h3>
          <AnimateHeight
            duration={duration}
            easing={ease}
            height={heightOutdoorFabrics}
          >
            <div className={styles.inner}>
              <ColorPanel 
                type={(excludedOutdoorFabrics && excludedOutdoorFabrics.length > 0) ? outdoorFabrics.edges.filter(post => (!excludedOutdoorFabrics.map(({outdoorFabric}) => outdoorFabric.id).includes(post.node.id))) : outdoorFabrics.edges} 
                typeParents={(excludedOutdoorFabrics && excludedOutdoorFabrics.length > 0) ? outdoorFabrics.edges.filter(post => (!post.node.parentItem && !excludedOutdoorFabrics.map(({outdoorFabric}) => outdoorFabric.id).includes(post.node.id))) : outdoorFabrics.edges.filter(post => (!post.node.parentItem))} 
                typeSlug="outdoor-fabrics"
                notificationBar={notificationBar} />
            </div>
          </AnimateHeight>
        </div>
      )}
      {enableTopFinishes && (
        <div className={styles.accordion}>
          <h3 className={styles.toggle} onClick={handleToggleTopFinishesAc}>Top Finishes</h3>
          <AnimateHeight
            duration={duration}
            easing={ease}
            height={heightTopFinishes}
          >
            <div className={styles.inner}>
              <ColorPanel 
                type={(excludedTopFinishes && excludedTopFinishes.length > 0) ? topFinishes.edges.filter(post => (!excludedTopFinishes.map(({topFinish}) => topFinish.id).includes(post.node.id))) : topFinishes.edges} 
                typeParents={(excludedTopFinishes && excludedTopFinishes.length > 0) ? topFinishes.edges.filter(post => (!post.node.parentItem && !excludedTopFinishes.map(({topFinish}) => topFinish.id).includes(post.node.id))) : topFinishes.edges.filter(post => (!post.node.parentItem))} 
                typeSlug="top-finishes"
                notificationBar={notificationBar} />
            </div>
          </AnimateHeight>
        </div>
      )}
      {enableGlassFinishes && (
        <div className={styles.accordion}>
          <h3 className={styles.toggle} onClick={handleToggleGlassFinishesAc}>Glass Finishes</h3>
          <AnimateHeight
            duration={duration}
            easing={ease}
            height={heightGlassFinishes}
          >
            <div className={styles.inner}>
              <ColorPanel 
                type={(excludedGlassFinishes && excludedGlassFinishes.length > 0) ? glassFinishes.edges.filter(post => (!excludedGlassFinishes.map(({glassFinish}) => glassFinish.id).includes(post.node.id))) : glassFinishes.edges} 
                typeParents={(excludedGlassFinishes && excludedGlassFinishes.length > 0) ? glassFinishes.edges.filter(post => (!post.node.parentItem && !excludedGlassFinishes.map(({glassFinish}) => glassFinish.id).includes(post.node.id))) : glassFinishes.edges.filter(post => (!post.node.parentItem))} 
                typeSlug="glass-finishes"
                notificationBar={notificationBar} />
            </div>
          </AnimateHeight>
        </div>
      )}
      {enableSeatFinishes && (
        <div className={styles.accordion}>
          <h3 className={styles.toggle} onClick={handleToggleSeatFinishesAc}>Seat Finishes</h3>
          <AnimateHeight
            duration={duration}
            easing={ease}
            height={heightSeatFinishes}
          >
            <div className={styles.inner}>
              <ColorPanel 
                type={(excludedSeatFinishes && excludedSeatFinishes.length > 0) ? seatFinishes.edges.filter(post => (!excludedSeatFinishes.map(({seatFinish}) => seatFinish.id).includes(post.node.id))) : seatFinishes.edges} 
                typeParents={(excludedSeatFinishes && excludedSeatFinishes.length > 0) ? seatFinishes.edges.filter(post => (!post.node.parentItem && !excludedSeatFinishes.map(({seatFinish}) => seatFinish.id).includes(post.node.id))) : seatFinishes.edges.filter(post => (!post.node.parentItem))} 
                typeSlug="seat-finishes"
                notificationBar={notificationBar} />
            </div>
          </AnimateHeight>
        </div>
      )}
      {enableWebbingFinishes && (
        <div className={styles.accordion}>
          <h3 className={styles.toggle} onClick={handleToggleWebbingFinishesAc}>Webbing Finishes</h3>
          <AnimateHeight
            duration={duration}
            easing={ease}
            height={heightWebbingFinishes}
          >
            <div className={styles.inner}>
              <ColorPanel 
                type={(excludedWebbingFinishes && excludedWebbingFinishes.length > 0) ? webbingFinishes.edges.filter(post => (!excludedWebbingFinishes.map(({webbingFinish}) => webbingFinish.id).includes(post.node.id))) : webbingFinishes.edges} 
                typeParents={(excludedWebbingFinishes && excludedWebbingFinishes.length > 0) ? webbingFinishes.edges.filter(post => (!post.node.parentItem && !excludedWebbingFinishes.map(({webbingFinish}) => webbingFinish.id).includes(post.node.id))) : webbingFinishes.edges.filter(post => (!post.node.parentItem))} 
                typeSlug="webbing-finishes"
                notificationBar={notificationBar} />
            </div>
          </AnimateHeight>
        </div>
      )}
      {enableDrawerFinishes && (
        <div className={styles.accordion}>
          <h3 className={styles.toggle} onClick={handleToggleDrawerFinishesAc}>Drawer Finishes</h3>
          <AnimateHeight
            duration={duration}
            easing={ease}
            height={heightDrawerFinishes}
          >
            <div className={styles.inner}>
              <ColorPanel 
                type={(excludedDrawerFinishes && excludedDrawerFinishes.length > 0) ? drawerFinishes.edges.filter(post => (!excludedDrawerFinishes.map(({drawerFinish}) => drawerFinish.id).includes(post.node.id))) : drawerFinishes.edges} 
                typeParents={(excludedDrawerFinishes && excludedDrawerFinishes.length > 0) ? drawerFinishes.edges.filter(post => (!post.node.parentItem && !excludedDrawerFinishes.map(({drawerFinish}) => drawerFinish.id).includes(post.node.id))) : drawerFinishes.edges.filter(post => (!post.node.parentItem))} 
                typeSlug="drawer-finishes"
                notificationBar={notificationBar} />
            </div>
          </AnimateHeight>
        </div>
      )}
      {enableDrawerBaseFinishes && (
        <div className={styles.accordion}>
          <h3 className={styles.toggle} onClick={handleToggleDrawerBaseFinishesAc}>Drawer & Base Finishes</h3>
          <AnimateHeight
            duration={duration}
            easing={ease}
            height={heightDrawerBaseFinishes}
          >
            <div className={styles.inner}>
              <ColorPanel 
                type={(excludedDrawerBaseFinishes && excludedDrawerBaseFinishes.length > 0) ? drawerBaseFinishes.edges.filter(post => (!excludedDrawerBaseFinishes.map(({drawerBaseFinish}) => drawerBaseFinish.id).includes(post.node.id))) : drawerBaseFinishes.edges} 
                typeParents={(excludedDrawerBaseFinishes && excludedDrawerBaseFinishes.length > 0) ? drawerBaseFinishes.edges.filter(post => (!post.node.parentItem && !excludedDrawerBaseFinishes.map(({drawerBaseFinish}) => drawerBaseFinish.id).includes(post.node.id))) : drawerBaseFinishes.edges.filter(post => (!post.node.parentItem))} 
                typeSlug="drawer-base-finishes"
                notificationBar={notificationBar} />
            </div>
          </AnimateHeight>
        </div>
      )}
      {enableFrameFinishes && (
        <div className={styles.accordion}>
          <h3 className={styles.toggle} onClick={handleToggleFrameFinishesAc}>Frame Finishes</h3>
          <AnimateHeight
            duration={duration}
            easing={ease}
            height={heightFrameFinishes}
          >
            <div className={styles.inner}>
              <ColorPanel 
                type={(excludedFrameFinishes && excludedFrameFinishes.length > 0) ? frameFinishes.edges.filter(post => (!excludedFrameFinishes.map(({frameFinish}) => frameFinish.id).includes(post.node.id))) : frameFinishes.edges} 
                typeParents={(excludedFrameFinishes && excludedFrameFinishes.length > 0) ? frameFinishes.edges.filter(post => (!post.node.parentItem && !excludedFrameFinishes.map(({frameFinish}) => frameFinish.id).includes(post.node.id))) : frameFinishes.edges.filter(post => (!post.node.parentItem))} 
                typeSlug="frame-finishes"
                notificationBar={notificationBar} />
            </div>
          </AnimateHeight>
        </div>
      )}
      {enableStructureFinishes && (
        <div className={styles.accordion}>
          <h3 className={styles.toggle} onClick={handleToggleStructureFinishesAc}>Structure Finishes</h3>
          <AnimateHeight
            duration={duration}
            easing={ease}
            height={heightStructureFinishes}
          >
            <div className={styles.inner}>
              <ColorPanel 
                type={(excludedStructureFinishes && excludedStructureFinishes.length > 0) ? structureFinishes.edges.filter(post => (!excludedStructureFinishes.map(({structureFinish}) => structureFinish.id).includes(post.node.id))) : structureFinishes.edges} 
                typeParents={(excludedStructureFinishes && excludedStructureFinishes.length > 0) ? structureFinishes.edges.filter(post => (!post.node.parentItem && !excludedStructureFinishes.map(({structureFinish}) => structureFinish.id).includes(post.node.id))) : structureFinishes.edges.filter(post => (!post.node.parentItem))} 
                typeSlug="structure-finishes"
                notificationBar={notificationBar} />
            </div>
          </AnimateHeight>
        </div>
      )}
      {enableLegFinishes && (
        <div className={styles.accordion}>
          <h3 className={styles.toggle} onClick={handleToggleLegFinishesAc}>Leg Finishes</h3>
          <AnimateHeight
            duration={duration}
            easing={ease}
            height={heightLegFinishes}
          >
            <div className={styles.inner}>
              <ColorPanel 
                type={(excludedLegFinishes && excludedLegFinishes.length > 0) ? legFinishes.edges.filter(post => (!excludedLegFinishes.map(({legFinish}) => legFinish.id).includes(post.node.id))) : legFinishes.edges} 
                typeParents={(excludedLegFinishes && excludedLegFinishes.length > 0) ? legFinishes.edges.filter(post => (!post.node.parentItem && !excludedLegFinishes.map(({legFinish}) => legFinish.id).includes(post.node.id))) : legFinishes.edges.filter(post => (!post.node.parentItem))} 
                typeSlug="leg-finishes"
                notificationBar={notificationBar} />
            </div>
          </AnimateHeight>
        </div>
      )}
      {enableBaseFinishes && (
        <div className={styles.accordion}>
          <h3 className={styles.toggle} onClick={handleToggleBaseFinishesAc}>Base Finishes</h3>
          <AnimateHeight
            duration={duration}
            easing={ease}
            height={heightBaseFinishes}
          >
            <div className={styles.inner}>
              <ColorPanel 
                type={(excludedBaseFinishes && excludedBaseFinishes.length > 0) ? baseFinishes.edges.filter(post => (!excludedBaseFinishes.map(({baseFinish}) => baseFinish.id).includes(post.node.id))) : baseFinishes.edges} 
                typeParents={(excludedBaseFinishes && excludedBaseFinishes.length > 0) ? baseFinishes.edges.filter(post => (!post.node.parentItem && !excludedBaseFinishes.map(({baseFinish}) => baseFinish.id).includes(post.node.id))) : baseFinishes.edges.filter(post => (!post.node.parentItem))} 
                typeSlug="base-finishes"
                notificationBar={notificationBar} />
            </div>
          </AnimateHeight>
        </div>
      )}
      {enableFinBaseFinishes && (
        <div className={styles.accordion}>
          <h3 className={styles.toggle} onClick={handleToggleFinBaseFinishesAc}>Fin Base Finishes</h3>
          <AnimateHeight
            duration={duration}
            easing={ease}
            height={heightFinBaseFinishes}
          >
            <div className={styles.inner}>
              <ColorPanel 
                type={(excludedFinBaseFinishes && excludedFinBaseFinishes.length > 0) ? finBaseFinishes.edges.filter(post => (!excludedFinBaseFinishes.map(({finBaseFinish}) => finBaseFinish.id).includes(post.node.id))) : finBaseFinishes.edges} 
                typeParents={(excludedFinBaseFinishes && excludedFinBaseFinishes.length > 0) ? finBaseFinishes.edges.filter(post => (!post.node.parentItem && !excludedFinBaseFinishes.map(({finBaseFinish}) => finBaseFinish.id).includes(post.node.id))) : finBaseFinishes.edges.filter(post => (!post.node.parentItem))} 
                typeSlug="fin-base-finishes"
                notificationBar={notificationBar} />
            </div>
          </AnimateHeight>
        </div>
      )}
      {enableFeetFinishes && (
        <div className={styles.accordion}>
          <h3 className={styles.toggle} onClick={handleToggleFeetFinishesAc}>Feet Finishes</h3>
          <AnimateHeight
            duration={duration}
            easing={ease}
            height={heightFeetFinishes}
          >
            <div className={styles.inner}>
              <ColorPanel 
                type={(excludedFeetFinishes && excludedFeetFinishes.length > 0) ? feetFinishes.edges.filter(post => (!excludedFeetFinishes.map(({feetFinish}) => feetFinish.id).includes(post.node.id))) : feetFinishes.edges} 
                typeParents={(excludedFeetFinishes && excludedFeetFinishes.length > 0) ? feetFinishes.edges.filter(post => (!post.node.parentItem && !excludedFeetFinishes.map(({feetFinish}) => feetFinish.id).includes(post.node.id))) : feetFinishes.edges.filter(post => (!post.node.parentItem))} 
                typeSlug="feet-finishes"
                notificationBar={notificationBar} />
            </div>
          </AnimateHeight>
        </div>
      )}
      {enableDrawings && (
        <div className={styles.accordion}>
          <h3 className={styles.toggle} onClick={handleToggleLineDrawingsAc}>Line Drawings</h3>
          <AnimateHeight
            duration={duration}
            easing={ease}
            height={heightLineDrawings}
          >
            <div className={styles.inner}>
              <LineDrawings drawings={drawings} />
            </div>
          </AnimateHeight>
        </div>
      )}
      {enableCompositions && (
        <div className={styles.accordion}>
          <h3 className={styles.toggle} onClick={handleToggleCompositionsAc}>Compositions</h3>
          <AnimateHeight
            duration={duration}
            easing={ease}
            height={heightCompositions}
          >
            <div className={styles.inner}>
              <Compositions compositions={compositions} />
            </div>
          </AnimateHeight>
        </div>
      )}
      {downloads && downloads.length > 0 && (
        <div className={styles.accordion}>
          <h3 className={styles.toggle} onClick={handleToggleDocumentationAc}>Documentation</h3>
          <AnimateHeight
            duration={duration}
            easing={ease}
            height={heightDocumentation}
          >
            <div className={styles.inner}>
              <Documentation downloads={downloads} />
            </div>
          </AnimateHeight>
        </div>
      )}
    </div>
  );
}

export default ProductDetails;
