// extracted by mini-css-extract-plugin
export var child = "product-color-panel-module--child--eIfb4";
export var children = "product-color-panel-module--children--3mUG0";
export var close = "product-color-panel-module--close--6VJs3";
export var grid = "product-color-panel-module--grid--puEl1";
export var header = "product-color-panel-module--header--2WmQI";
export var image = "product-color-panel-module--image--rvakR";
export var item = "product-color-panel-module--item--H8w-E";
export var link = "product-color-panel-module--link--kBU-B";
export var parents = "product-color-panel-module--parents--btMTk";
export var root = "product-color-panel-module--root--HcRK+";
export var technicalDetails = "product-color-panel-module--technicalDetails--dRheg";
export var title = "product-color-panel-module--title--qmtpb";