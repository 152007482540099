import React from "react";
import { cn } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";
import PortableText from "./portableText";

import * as styles from "../styles/components/product-line-drawings.module.css";

const LineDrawings = ({ drawings }) => {

  return (
    <div className={styles.root}>
      <div className={cn("grid", styles.items)}>
        {drawings.map(({ _key, thumbnail, name, partNumber, _rawMeasurements }) => {
          return (
            <div className={cn("col-12 col-sm-6 col-lg-4", styles.item)} key={_key}>
              {thumbnail && thumbnail.asset && (
                <div className={styles.image}>
                  <a href={imageUrlFor(buildImageObj(thumbnail)).url()} target="_blank" rel="noreferrer">
                    <img
                      src={imageUrlFor(buildImageObj(thumbnail))
                        .auto("format")
                        .url()}
                      alt={thumbnail.alt}
                    />
                  </a>
                </div>
              )}
              {name && (<h4>{name}</h4>)}
              {partNumber && (<h4>{partNumber}</h4>)}
              {_rawMeasurements && (<div className={styles.measurements}><PortableText blocks={_rawMeasurements} /></div>)}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default LineDrawings;
